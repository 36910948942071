module.exports = {
  siteTitle: 'Oguzhan Curavcy | Web Developer & Graphic Designer',
  siteDescription:
    'Performance driven Web Development and Instructional Design Professional with extensive experience in impacting organizational performance through website content and training program design and facilitation, team leadership and process implementation. Proven ability to oversee the design, development, implementation and management of website.',
  siteKeywords: 'Oguzhan, Curavci, Web developer, graphic designer, software engineer',
  siteUrl: 'https://curavcy.com',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'UA-159942341-2',
  googleVerification: '',
  name: 'Oguzhan Curavci',
  location: 'Seattle, WA',
  email: 'curavcy@gmail.com',
  github: 'https://github.com/curavcy',
  twitterHandle: '',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/curavcy',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/oguzhan-curavci-1b385a61',
    },
    {
      name: 'Codepen',
      url: 'https://codepen.io/',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/curavcy',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Work',
      url: '/#projects',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,

  colors: {
    green: '#64ffda',
    navy: '#0a192f',
    darkNavy: '#14213d',
  },

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
