import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297.21 172.01">
    <title>Loader Logo</title>

    <path d="M156.06,40a85,85,0,0,0,1.18,92,85,85,0,0,0-1.18-92Z" fill="#2b74bb" />
    <path
      d="M143.13,86.93a58.29,58.29,0,1,1,0-2.19c-.12-10.43.55-20.42,4.3-30.26,1.93-4.95,4.36-11.53,7.19-16-15.25-22.85-39.95-36.6-69.48-36.6A85.09,85.09,0,1,0,157.24,132a84.65,84.65,0,0,1-14.08-45.5Z"
      fill="#2b74bb"
    />
    <path
      d="M170.17,85.09h0a58,58,0,0,1,107.56-30.3l19.48-19.48A85.07,85.07,0,0,0,156.06,40a84.69,84.69,0,0,1,14.09,45.51Z"
      fill="#2b74bb"
    />
    <path
      d="M228.22,143.13a58,58,0,0,1-58-55.85c.12,10.43-1.19,21.6-4.94,31.43a86.15,86.15,0,0,1-7,14.44c15.25,22.85,40.37,37,69.91,37A84.91,84.91,0,0,0,295.64,137l-19.35-19.34A58,58,0,0,1,228.22,143.13Z"
      fill="#2b74bb"
    />
    <path
      d="M227.88,143.13a58,58,0,0,1-58-55.85c.12,10.43-1.18,21.6-4.94,31.43a85.38,85.38,0,0,1-7,14.44c15.26,22.85,40.37,37,69.91,37A84.91,84.91,0,0,0,295.3,137L276,117.63A58,58,0,0,1,227.88,143.13Z"
      fill="#2b74bb"
    />
  </svg>
);

export default IconLoader;
